/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import 'node_modules/@elastic/eui/src/themes/amsterdam/colors_light';
@import 'node_modules/@elastic/eui/src/themes/amsterdam/globals';



@import '@modules/cui/styles/variables';

.topologyPanel {
  position: relative;
  margin: 0 0 $paddingJumbo;
}

.topologyPanel-row {
  margin-left: 0;
}

.topologyPanel-row-label {
  text-align: right;

  @media screen and (max-width: $euiBreakpointM) {
    text-align: left;
  }
}

.topologyPanel-unshadedArea {
  padding: 0 $paddingLargest $paddingLargest;
}

.topologyPanel-shadedArea {
  padding: $paddingLargest;
  border-radius: 0 0 $euiBorderRadius $euiBorderRadius;
}

.topologyPanel-sizePicker {
  margin-top: -8px;
}

.topologyPanel-sizeSlider-radioLegend {
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: $euiSizeM * 3; // make flush with label: 2 for the gutter, 1 for the extra padding added to the label above
  text-align: right;
}

// make the AZ radios inline and normal font size instead of EUI's smaller font size
.topologyPanel-zoneCount {
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: $paddingEvenLarger;

  label {
    font-size: inherit;
    z-index: 0;
  }
}

.topologyPanel-title {
  margin: $paddingSmaller $euiSize $euiSize $euiSize;
}


.instance-heading-rule {
  margin-left: 12px !important;
  width: 80px !important;
  height: 2px;
  background: $euiColorPrimary;
}
