/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import 'node_modules/@elastic/eui-theme-borealis/src/theme_light.scss';



@import '@modules/cui/styles/variables.scss';

.password-field-popover {
  max-width: 350px;
}

.password-field-component {
  .password-strength-meter-super-strong {
    &::-webkit-progress-value {
      background-color: $euiColorSuccess;
      background-image: linear-gradient(to right,
        $euiColorDanger,
        $euiColorVis7,
        $euiColorVis5,
        $euiColorVis0,
        $euiColorSuccess,
        $euiColorPrimary,
        $euiColorVis3,
        $euiColorAccent
      );
    }

    &::-moz-progress-bar {
      background-color: $euiColorSuccess;
      background-image: linear-gradient(to right,
        $euiColorDanger,
        $euiColorVis7,
        $euiColorVis5,
        $euiColorVis0,
        $euiColorSuccess,
        $euiColorPrimary,
        $euiColorVis3,
        $euiColorAccent
      );
    }
  }

  .password-strength-meter-amber {
    color: $euiColorWarning;

    &::-webkit-progress-value {
      background-color: $euiColorWarning;
    }

    &::-moz-progress-bar {
      background-color: $euiColorWarning;
    }
  }
}
