@use "sass:meta" as ---zx2240i7pum;/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import './variables';

.u-noPadding {
  padding: 0;
  margin: 0;
}

.u-quiet {
  color: $euiColorMediumShade;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: none;
}

.nowrap {
  white-space: nowrap;
}

// utility classes for styling the background with the major semantic colors

.background-warning {
  &, &:hover {
    background-color: euiCallOutColor('warning', 'background');
  }
}

.background-danger {
  &, &:hover {
    background-color: euiCallOutColor('danger', 'background');
  }
}

;@include ---zx2240i7pum.load-css("sass-embedded-legacy-load-done:389");