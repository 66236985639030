/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

// This is used instead of a spacer, as we can't embed them within
// EuiStat components as they will then nest a <div> within a <p>.
$snapshotStatSpacing: 0.5rem; // 8px

.snapshotStat {
  margin-bottom: $snapshotStatSpacing;

  .euiStat__description {
    margin-bottom: $snapshotStatSpacing;
  }
}
