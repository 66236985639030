/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import 'node_modules/@elastic/eui/src/themes/amsterdam/colors_light';
@import 'node_modules/@elastic/eui/src/themes/amsterdam/globals';



@import '@modules/cui/styles/variables.scss';


.actionsDropdown-delete-deployment {
  color: $euiColorDanger;
}

.cloudId-container {
  white-space: nowrap;
}

.cloudId {
  word-break: break-all;
  white-space: normal;
  width: 30rem;
  max-width: 30rem;

  @media only screen and (max-width: $euiBreakpointM) {
    max-width: 27rem;
  }
  @media only screen and (max-width: $euiBreakpointS) {
    max-width: 98%;
  }
}

.euiLink.docLink {
  display: inline-flex;
  align-items: center;

  &:focus {
    background-color: transparent;
    outline: none;
  }

  .euiIcon {
    margin-left: $paddingSmall;
    margin-top: $paddingTiny;
  }

  &:hover {
    .euiIcon {
      transform: translateY(-2px);
    }
  }
}

.cloudId-description {
  width: 15rem;

  .euiLink.docLink {
    .euiIcon {
      margin-left: $paddingSmall;
      padding-bottom: $paddingTiny;
    }
  }
}

.overview-grid {
  > .euiFlexItem {
    min-height: 4.5rem;
  }
}

.deploymentOverview-managementControls {
  justify-content: flex-end;

  @media only screen and (max-width: $euiBreakpointS) {
    justify-content: flex-start;
  }
}

.deploymentOverview-Applications {
  @media only screen and (max-width: $euiBreakpointL) {
    max-width: 98%;
  }
}

.deployment-tags {
  width: auto;
}
