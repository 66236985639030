/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

.cuiSmallErrorBoundary-wrapper,
.cuiSmallErrorBoundary-wrapper .euiToolTipAnchor,
.cuiSmallErrorBoundary-content {
  width: 100%;
  height: 100%;
}

.cuiSmallErrorBoundary-wrapper .euiToolTipAnchor {
  display: block;
}

.cuiSmallErrorBoundary-content::before {
  content: ' ';
  white-space: pre;
  pointer-events: none;
  user-select: none;
}

.cuiSmallErrorBoundary-tooltip {
  width: 550px;
  max-width: calc(100% - 10px);
}

.cuiSmallErrorBoundary--forInlineText {
  display: inline-block;
  vertical-align: middle;
  height: 1rem;
  width: 8rem;

  .cuiSmallErrorBoundary-content {
    padding: 0;
  }
}
