@use "sass:meta" as ---bftjn1pg8c8;/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import './mixins/containers';
@import './variables';

/* stylelint-disable selector-max-id */
#app-root {
  /* stylelint-enable selector-max-id */
  @include indifferentRootContainer();
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: $paddingLarge;
}

.fullWidth {
  margin-left: -$paddingBase;
  margin-right: -$paddingBase;
}

// smooth transition between light and dark modes
html,
.euiHeader,
.euiHeaderSection,
.euiPanel {
  transition:
    background-color $euiAnimSpeedNormal ease,
    border-color $euiAnimSpeedNormal ease;
}

.euiTitle,
.euiSideNavItemButton {
  transition: color $euiAnimSpeedNormal ease;
}

;@include ---bftjn1pg8c8.load-css("sass-embedded-legacy-load-done:42");