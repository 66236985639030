/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import 'node_modules/@elastic/eui/src/themes/amsterdam/colors_light';
@import 'node_modules/@elastic/eui/src/themes/amsterdam/globals';



@import '@modules/cui/styles/variables.scss';

.cloud-landing-page {
  .email-verification-page {
    max-width: 650px;

    .cloud-landing-page-panel-image {
      width: auto;
      height: 220px;
    }
  }
}

.email-verification-prompt {
  margin-top: $euiSizeXL;

  .euiTitle {
    margin-bottom: $euiSizeS;
  }

  .email-verification-actions {
    margin-top: -$euiSizeL;
  }

  & > .euiIcon {
    height: 290px;
    width: 300px;
  }
}
