/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import 'node_modules/@elastic/eui/src/themes/amsterdam/colors_light';
@import 'node_modules/@elastic/eui/src/themes/amsterdam/globals';



@import '@modules/cui/styles/variables.scss';

.cloudContent {
  &.cloud-portal {
    &.cloud-portal-landing-page {
      background-color: $euiPageBackgroundColor;

      .cloud-portal-page-content {
        border: none;
      }
    }

    &.cloud-portal-account, &.cloud-portal-user-account-settings {
      .cloud-portal-page-content {
        border-top: 1px solid $euiColorLightShade;

        .euiTheme-dark & {
          border-color: $euiColorDarkestShade;
        }

      }
    }

    .cloud-portal-header {
      padding-bottom: $paddingEvenLarger;
    }

    .cloud-portal-header,
    .cloud-portal-page-content-body {
      width: 100%;
      max-width: 1600px;
      margin: 0 auto;
    }

    .cloud-portal-page-content {
      border-radius: 0;
      border-right: none;
      border-left: none;
      background-color: transparent;


      &.cloud-page-tabbed-content {
        margin-top: $paddingJumbo;
        padding: 0;
      }

      .cloud-page-tab-panel {
        position: relative;
        top: -40px;

        &.euiTabs:not(.euiTabs--condensed) {
          &::before {
            background-color: transparent;
          }
        }
      }
    }
  }
}
