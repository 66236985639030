@use "sass:meta" as ---zx2240i7pum;/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/****
 ** Root Container Mixins
 ****
 *
 * These mixins are used to define how root-level containers
 * behave visually. They hug the window as tightly as
 * possible and allow their children to render themselves
 * as if they didn't have any containers.
 **/

/**
 * indifferentRootContainer()
 *
 * This mixin is used by elements like `html`, `body`, and
 * the `#app-root` to provide a stable base for
 * the app. Elements with this mixin don't define a specific
 * and will adjust to their children.
 *
 * Evey element assigned this mixin should have one child
 * element, and it should have one of these mixins applied.
 */
@mixin indifferentRootContainer() {
  width: 100%;
  height: 100%;
}

/**
 * staticRootContainer()
 *
 * this mixin is used by apps like the /login view to render
 * a full-screen app that does not scroll
 **/
@mixin staticRootContainer() {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

;@include ---zx2240i7pum.load-css("sass-embedded-legacy-load-done:325");