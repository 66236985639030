/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import 'node_modules/@elastic/eui-theme-borealis/src/theme_light.scss';



@import '@modules/cui/styles/variables.scss';

.apiConsole {
  .ReactCodeMirror {
    // Default styling caps this to 700px, but for responses from the cluster that seems unnecessary.
    max-width: initial;
  }
}

.apiConsole--requestBody {
  margin-top: $paddingBase;
}

.apiConsole--response {
  margin: 0;
  padding: $paddingBase;
}

.apiConsole--response-info {
  background-color: $euiColorLightShade;
}

.apiConsole--response-success {
  background-color: $euiColorSuccess;
  color: $euiColorEmptyShade;
}

.apiConsole--response-redirect {
  background-color: $euiColorWarning;
}

.apiConsole--response-error {
  background-color: $euiColorDanger;
  color: $euiColorEmptyShade;
}

.apiConsole--outputBody {
  word-break: break-word;
}
