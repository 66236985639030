/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import 'node_modules/@elastic/eui-theme-borealis/src/theme_light.scss';



@import '@modules/cui/styles/variables.scss';

.http-error-pages {
  .euiPageContent {
    width: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
  }

  .euiEmptyPrompt {
    max-width: 100%;

    img {
      &.euiIcon {
        width: 450px;
        height: auto;
      }
    }

    .http-error-pages-button {
      width: 120px;
    }
  }
}

@media only screen and (max-width: $euiBreakpointM) {
  .http-error-pages-button {
    width: 100%;
  }
  .http-error-pages {
    .euiEmptyPrompt {
      img {
        &.euiIcon {
          width: 100%;
        }
      }
    }
  }
}
