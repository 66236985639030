/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import 'node_modules/@elastic/eui-theme-borealis/src/theme_light.scss';



@import '../../styles/variables.scss';

.euiCodeEditorWrapper {
  position: relative;

  .ace_hidden-cursors {
    opacity: 0;
  }

  &.euiCodeEditorWrapper-isEditing {
    .ace_hidden-cursors {
      opacity: 1;
    }
  }
}

.euiCodeEditorKeyboardHint {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;

  &:focus {
    opacity: 1;
    border: 2px solid $euiColorPrimary;
    z-index: $euiZLevel1;
  }

  &.euiCodeEditorKeyboardHint-isInactive {
    display: none;
  }
}
